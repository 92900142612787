import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribtionForm from "../components/subscribtionForm"

const A = tw.a`
underline 
`
const link = (text, url, endbool = true) => {
  const end = endbool ? " " : ""
  return (
    <>
      {" "}
      <A href={url} target="_blank" rel="noreferrer">
        {text}
      </A>
      {end}
    </>
  )
}

const BlogIndex = ({ data, location }) => {
  const { title } = data.site.siteMetadata
  return (
    <Layout location={location} title={title}>
      <SEO title="About" description="Welcome to my blog!" />
      <main className="max-w-2xl mx-auto p-2 mt-4 pt-16 md:text-xl text-darkgrey font-sans">
        👋 Welcome to my glorified blog!
        <br />
        <br />
        My name is
        {link("Ahmed Hosny", "https://ahmedhosny.com/", false)}. I write on
        developing and implementing machine learning strategies for improving
        human health. I would be thrilled if the ideas discussed here interest
        you enough to explore further. I also hope I have earned a new friend!
        <div className="my-4 text-base">
          <SubscribtionForm />
        </div>
        I am a machine learning research scientist. I work with biomedical
        imaging data ranging from cell microscopy and histopathology to clinical
        CT and PET. My work has been published in Science, Nature Reviews
        Cancer, and PLoS Medicine among others.
        <br />
        <br />
        Currently, I work with{" "}
        {link("Spring Discovery", "https://www.springdiscovery.com/")} and have
        previously been with the{" "}
        {link("Dana Farber Cancer Institute", "https://www.dana-farber.org/")}{" "}
        and the {link("Wyss Institute", "https://wyss.harvard.edu/", false)}. In
        a former life, I was trained as an architect and spent 4 years in China
        working with star architects on large construction projects.
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
